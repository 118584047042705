import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import { Auth0Provider, withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react'; // Import the Auth0 hook
import HomePage from './HomePage';
import Profile from './Profile';
import Header from "./Header";
import PrivacyPolicy from "./PrivacyPolicy";
import './App.css';
import ReactGA from "react-ga4";

const ProtectedRoute = ({ component, ...args }) => {
  const Component = withAuthenticationRequired(component, args);
  return <Component />;
};

const Auth0ProviderWithRedirectCallback = ({ children, ...props }) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };
  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

export default function App() {
  useEffect(() => {
    ReactGA.initialize("G-");
  }, []);

  return (
    <BrowserRouter>
      <Auth0ProviderWithRedirectCallback
      domain="instructllm.us.auth0.com"
      clientId="SLroBFVWqFSDzhPK4rVvWuoGLn3WM5nZ"
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: "https://instructllm.us.auth0.com/api/v2/",
          scope: "openid profile email read:current_user update:current_user_metadata"
        }}
      >
        <HeaderWrapper />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route
            path="/app"
            element={<ProtectedRoute component={Profile} />}
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </Auth0ProviderWithRedirectCallback>
    </BrowserRouter>
  );
}

const HeaderWrapper = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  return <Header isAuthenticated={isAuthenticated} loginWithRedirect={loginWithRedirect} />;
};
