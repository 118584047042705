import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

const Profile = () => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [topic, setTopic] = useState('');
  const [markdownResponse, setMarkdownResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Loading state added

  const domain = "alphainquire.us.auth0.com"; 

  const fetchFromApi = async () => {
    try {
      setIsLoading(true);  // Start the loading state

      const token = await getAccessTokenSilently({
        audience: `https://${domain}/api/v2/`,
        scope: "read:current_user",
      });
  
      const response = await fetch(`https://cpdough--instruct-llm-main.modal.run`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ topic: topic })
      });
      
      const data = await response.json();
      
      setMarkdownResponse(data.markdown || '');
    } catch (e) {
      console.error("An error occurred while fetching data:", e);
    } finally {
      setIsLoading(false);  // End the loading state
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(markdownResponse);
  };

  return (
    isAuthenticated && (
      <div className="flex flex-col items-center p-5">
        <div className="pb-12 max-w-2xl w-full text-center">
          <h1 className="text-2xl font-bold tracking-tight leading-7 text-gray-900">Let's Instruct your LLM, {user.name}!</h1>
          <p className="text-sm text-gray-500">1. Submit your Topic 2. AI Researches your Topic 3. Copy and Paste into your LLM Prompt</p>

        </div>
      
        <div className="max-w-2xl w-full mt-2 text-center">
          <input
            type="text"
            value={topic}
            onChange={(e) => setTopic(e.target.value)}
            placeholder="Give AI a URL or topic to research..."
            className="border border-gray-300 p-2 max-w-lg w-full"
          />
          <button type="button" onClick={fetchFromApi} className="ml-2 bg-blue-500 text-white p-2 rounded">
            Start Research
          </button>
        </div>

        <div className="max-w-2xl w-full mt-5 text-center border border-gray-300 p-4 relative select-all">
          {isLoading ? (
            <div>
              <p class="italic">Loading...</p>Your AI agent is conducting research. Don't go anywhere.<br /><br />Joke while you wait?<br /><br />What does AI and your senile grandpa have in common?<br />A knowledge cutoff date
            </div> 
          ) : (
            <>
              {markdownResponse}
              {markdownResponse && (
                <button
                  type="button"
                  onClick={copyToClipboard}
                  className="absolute top-2 right-2 bg-white p-1 rounded border border-gray-300"
                  title="Copy to Clipboard"
                >
                  <FontAwesomeIcon icon={faCopy} />
                </button>
              )}
            </>
          )}
        </div>

        <hr />
        <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />

        <stripe-buy-button
          buy-button-id="buy_btn_1NmSDWKFxdT22QzoQ0Jn2yF1"
          publishable-key="pk_live_51NJmx5KFxdT22QzoUTwBpAcJDVz5ATfLHqpkXyEsvm6VhO0ihXtfSIpYXaSyPsimxe8EaESS48folMuJ3l313grm00wxZe9NZw"
          customer-email={user.email}
          client-reference-id={user.email}
        ></stripe-buy-button>
      </div>
    )
  );
};

export default Profile;